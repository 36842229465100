import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import tokenomicsImage from "../../../../assets/images/tokenomicsImage.png";
import mikeyVideo from "../../../../assets/images/mikeyVideo.mp4";
import Paragraph from "../../Paragraph";
import Footer from "../S4-Footer";
const ContinerWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 90px);
  width: 100%;
  background-color: #bae3c5;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  position: relative;
  min-height: calc(100dvh - 90px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
`;
const Video = styled.video`
  width: 70%;
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const Tokenomics = () => {
  return (
    <>
      <ContinerWraper id="Tokenomics">
        <InnerContinerWraper>
          <CustomGrid container jc="start" g599="40px" p599="0 0 100px">
            <CustomGrid
              item
              xs={12}
              sm={5}
              md={4}
              fd="column"
              ai="start"
              g="20px"
            >
              <SectionHeading>Tokenomics</SectionHeading>
              <Paragraph color="#000">
                Name: Technicolor Mickey <br />
                Symbol: MICKEY2 <br />
                Supply: 1000000000 <br />
                Taxes: 0/0
                <br />
                <br />
                <span style={{ fontSize: "20px" }}>
                  Contract:
                  <br /> 0xasjifbsdfugnovseyt487t342
                </span>
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} sm={7} md={8} jc="end">
              <Video autoPlay muted loop>
                <source src={mikeyVideo} type="video/mp4" />
              </Video>
            </CustomGrid>
          </CustomGrid>
          <AbsoluiteImage
            t="100%"
            r="20%"
            style={{ transform: "translate(20%,-60%)", zIndex: "1" }}
            mw="30%"
            src={tokenomicsImage}
          />
        </InnerContinerWraper>
        <div
          style={{
            width: "100%",
            height: "70px",
            background: "#EECB00",
            position: "relative",
          }}
        />
      </ContinerWraper>
      <Footer />
    </>
  );
};

export default Tokenomics;
