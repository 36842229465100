import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
import footerImage from "../../../../assets/images/footerImage.png";
const ContinerWraper = styled.div`
  overflow: hidden;
  min-height: auto;
  width: 100%;
  background-color: #eecb00;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: auto;
  width: 100%;
  padding: 30px 24px 0 !important;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: start;
`;


const Footer = () => {
  return (
    <>
      <ContinerWraper id="Footer">
        <InnerContinerWraper>
          <CustomGrid container jc="space-between"  g599="30px" >
            <CustomGrid item xs={12} sm={5} md={3}>
              <DataImage mw="70%" src={footerImage} />
            </CustomGrid>
            <CustomGrid
              item
              xs={12}
              sm={5}
              md={2}
              fd="column"
              ai="end"
              ai599 ="center"
              g="30px"
            >
              <Paragraph
                sx={{ fontSize: { xs: "14px", sm: "14px" },textAlign: { xs: "center", sm: "right" } }}
                textAlign="right"
                fontFamily="ComicSansR"
                color="#000"
              >
                Have questions, suggestions, or just want to share your
                excitement for Technicolor Mickey? Join our socials, our
                community and team is here to assist you on this magical crypto
                adventure.
              </Paragraph>
              <div style={{ display: "flex", gap: "30px" }}>
                <Link href="https://t.me/tcmickeycoin" target="blank">
                  <DataImage mw="30px" src={tg} />
                </Link>
                <Link href="https://x.com/tcmickeycoin" target="blank">
                  <DataImage mw="27px" src={x} />
                </Link>
              </div>
            </CustomGrid>
            <CustomGrid container item xs={12}>
              <CustomGrid
                sx={{
                  background: "#000",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
                p="10px"
                item
                xs={12}
                sm={5}
                md={3}
              >
                <Paragraph
                  textAlign="center"
                  color="#EECB00"
                >
                 info@technicolormickey
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Footer;
