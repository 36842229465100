import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";

const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <AboutSection />
    </PageWrapper>
  );
};

export default MainPage;
