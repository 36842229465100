import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import aboutSideImage from "../../../../assets/images/aboutSideImage.png";
import textrightImage from "../../../../assets/images/textrightImage.png";
import bottomImage from "../../../../assets/images/bottomImage.png";
import Paragraph from "../../Paragraph";
import Tokenomics from "../S3-Tokenomics";
const ContinerWraper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  @media (max-width: 599px) {
    padding: 60px 0 0 !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  position: relative;
  min-height: calc(100dvh);
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: start;
`;
const AbsoluiteImage1 = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "-70px")};
  right: ${(props) => (props.r ? props.r : "-100px")};
  /* transform: ${(props) =>
    props.tran ? props.tran : "translate(-50%,0%)"}; */
  max-width: ${(props) => (props.mw ? props.mw : "200px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "120px")};
    top: ${(props) => (props.t ? props.t : "-40px")};
    right: ${(props) => (props.r ? props.r : "-10px")};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const AboutSection = () => {
  return (
    <>
      <ContinerWraper id="about">
        <InnerContinerWraper>
          <CustomGrid container jc="start" g599="40px" p599="0 0 100px">
            <CustomGrid
              item
              xs={12}
              sm={5}
              md={4}
              fd="column"
              ai="start"
              g="20px"
            >
              <AbsoluiteImage1 src={textrightImage} />
              <SectionHeading>About</SectionHeading>
              <Paragraph color="#000">
                The success of "Steamboat Willie”, Mickey in black-and-white,
                swept audiences off their feet, and brought a magical element to
                animation film that was beyond imagination. In 1930, Walt Disney
                used the combination of Mickey Mouse's popularity and
                Technicolor to create something the world has never seen;
                “Technicolor Mickey”! This inevitably led to the success and
                innovation of Disney's animation studio which also played a
                pivotal role in establishing Disney as a major player in the
                entertainment industry. In other words, without Technicolor
                Mickey, there would be no Disney. The Mickey character single
                handedly popularized the Disney brand overnight, turning him
                into a cultural phenomenon and anointing him as the official
                mascot of The Walt Disney Company that spread across the globe.
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} sm={7} md={8} jc="center">
              <DataImage
                mw="70%"
                mw599="100%"
                src={aboutSideImage}
                style={{ mixBlendMode: "darken" }}
              />
            </CustomGrid>
          </CustomGrid>
          <AbsoluiteImage
            t="100%"
            r="50%"
            style={{ transform: "translate(50%,-70%)" }}
            mw="20%"
            mw599="50%"
            src={bottomImage}
          />
        </InnerContinerWraper>
        <div
          style={{
            width: "100%",
            height: "90px",
            background: "#BAE3C5",
            position: "relative",
          }}
        />
      </ContinerWraper>

      <Tokenomics />
    </>
  );
};

export default AboutSection;
