import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import heroImage from "../../../../assets/images/heroImage.png";
import tg from "../../../../assets/images/tg.png";
import x from "../../../../assets/images/x.png";
const ContinerDiv = styled.div`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  background-image: url(${heroImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh);
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: end;
`;
const HeroSection = () => {
  return (
    <>
      <ContinerDiv id="home">
        <InnerContinerWraper>
          <CustomGrid container jc="end">
            <CustomGrid
              item
              xs={12}
              sm={5}
              md={3.2}
              fd="column"
              ai="end"
              ai599="center"
              g="30px"
              g599="50px"
            >
              <SectionHeading sx="">
                Technicolor <br />
                <span style={{ fontSize: "70px" }}>Mickey</span>
              </SectionHeading>
              <Paragraph
                sx={{
                  fontSize: { xs: "14px", sm: "16px" },
                  textAlign: { xs: "center", sm: "right" },
                }}
                fontFamily="ComicSansR"
                color="#000"
              >
                Technicolor Mickey was the first ever color animated Disney
                character created by Walt Disney in the 1930’s that was inspired
                by Walt’s earliest version of Mickey that debued in the animated
                short 1928 film "Steamboat Willie".
              </Paragraph>
              <div style={{ display: "flex", gap: "30px" }}>
                <Link href="https://t.me/tcmickeycoin" target="blank">
                  <DataImage mw="40px" src={tg} />
                </Link>
                <Link href="https://x.com/tcmickeycoin" target="blank">
                  <DataImage mw="32px" src={x} />
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerDiv>
    </>
  );
};

export default HeroSection;
